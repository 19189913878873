import { getWorkspaceProcCodes, getWorkspaceVariableNames } from "../../utils/malrangHideBlock";

const BLOCKLY_FLYOUT_SCROLLBAR_CLASS_NAME = 'blocklyScrollbarVertical blocklyFlyoutScrollbar';

class MalrangTutorialWorkspaceService {
    constructor(malrangTutorialModal) {
        this.vm = malrangTutorialModal.props.vm;
        this.workspace = malrangTutorialModal.props.workspace;
        this.scratchBlocks = malrangTutorialModal.props.scratchBlocks;
        this.malrangTutorialModal = malrangTutorialModal;

        this.blocklyFlyout = this.workspace.getFlyout();
        this.blocklyFlyoutSvg = this.blocklyFlyout.svgGroup_;
        this.blocklyToolbox = this.workspace.toolbox_.HtmlDiv;
        this.injectionDiv = this.workspace.blockDragSurface_.container_;
        this.blocklyFlyoutScrollbar = this.injectionDiv.getElementsByClassName(BLOCKLY_FLYOUT_SCROLLBAR_CLASS_NAME)[0];

        this.workspaceVariableNames = getWorkspaceVariableNames(this.vm.runtime, true);
        this.workspaceProcCodes = getWorkspaceProcCodes(this.vm.runtime, true);

        this.hiddenBlockIdsInWorkspace = this.vm.runtime.hiddenBlockIdsInWorkspace || new Map();

        const editingTarget = this.vm.runtime.getEditingTarget();
        const originBlockXML = editingTarget.blocks.toXML({});
        this.editingTargetWorkspaceXML = 
            `<xml xmlns="http://www.w3.org/1999/xhtml">
                ${originBlockXML}
            </xml>`;

        const blockXml = Object.values(this.vm.runtime.targets)
            .map(target => target.blocks.toXML({}))
            .join(' ');
        this.allBlocksXml = `
            <xml xmlns="http://www.w3.org/1999/xhtml">
                ${blockXml}
            </xml>`;
    }

    scrollWorkspaceToolboxToTop() {
        this.workspace.toolbox_.scrollToTop();
    }

    loadWorkspaceFromXML(xml) {
        const dom = this.scratchBlocks.Xml.textToDom(xml);
        try {
            const blockIds = this.scratchBlocks.Xml.clearWorkspaceAndLoadFromXml(dom, this.workspace);
            const blocks = blockIds.map(blockId => {
                return this.workspace.getBlockById(blockId);
            });
            setTimeout(() => {
                const blockSvg = blocks.filter(block => {
                    return block.getSvgRoot.call(block);
                }).map((block) => {
                    const blockSvgGroup = block.getSvgRoot.call(block);
                    return blockSvgGroup;
                });
                this.malrangTutorialModal.setState({ blockSvgGroups: blockSvg });
            })
        } catch (error) {
            if (error.message) {
                error.message = `Workspace Update Error In Malrang: ${error.message}`;
            }
            console.error(error);
        }
    }

    resetWorkspace() {
        this.loadWorkspaceFromXML(this.editingTargetWorkspaceXML);
    }

    loadAllBlocks() {
        this.loadWorkspaceFromXML(this.allBlocksXml);
    }

    onUpdateBlocksFiltering() {
        this.workspaceVariableNames = getWorkspaceVariableNames(this.vm.runtime, false);
        this.workspaceProcCodes = getWorkspaceProcCodes(this.vm.runtime, false);

        this.vm.runtime.cachedWorkspaceVariableNames = this.workspaceVariableNames;
        this.vm.runtime.cachedWorkspaceProcCodes = this.workspaceProcCodes;

        const targets = Object.values(this.vm.runtime.targets);
        const xmlString = targets.map(target => target.blocks.toXML({})).join(' ');

        this.vm.runtime.cachedWorkspaceXMLInAllTargets = xmlString;
    }

    setFlyoutVisible(value) {
        if (!this.blocklyFlyout) {
            return;
        }

        this.blocklyFlyout.setVisible(value);
    }
}

export default MalrangTutorialWorkspaceService;