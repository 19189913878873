import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import styles from './teachable-machine.css';
import LoadTmModelIcon from './load_tm_model.svg';
import TrashCanIcon from './tm_trash_can.svg';

const IS_IN_SEMOBAE = !!window.semobaeAPI;

const TeachableMachineComponent = props => {
    const sampleFileInputRef = React.useRef(null);

    return (
        <Modal
            id="teachableMachineModal"
            className={styles.modalContent}
            contentLabel={props.title}
            onRequestClose={props.onCancel}
        >
            <Box className={styles.body}>
                <Box className={styles.label}>
                    {props.label}
                </Box>
                <Box>
                    <div className={styles.absoluteBox}>
                        <input
                            autoFocus
                            className={styles.variableNameTextInput}
                            placeholder={props.placeholder}
                            value={props.loadedFileName || props.url}
                            name={props.label}
                            readOnly={!!props.loadedFileName}
                            onChange={props.onChange}
                        />
                        <label for="input-load-tm-model-file">
                            <img src={LoadTmModelIcon} className={styles.loadTmModelIcon} />
                        </label>
                        <input
                            type="file"
                            id="input-load-tm-model-file"
                            style={{ display: 'none' }}
                            accept=".zip"
                            onChange={props.onLoadModelZip}
                            ref={sampleFileInputRef}
                        />
                        {props.loadedFileName && (
                            <img src={TrashCanIcon} className={styles.deleteLoadedFileIcon} onClick={() => props.onClearInput(sampleFileInputRef.current)} />
                        )}
                    </div>
                    {props.isAudioModel && (
                        <div className={styles.audioModelAlertMessage}>죄송합니다. 오디오 모델 학습은 점검 중입니다. 빠르게 처리해 사용할 수 있도록 하겠습니다.</div>
                    )}
                </Box>
                <Box className={styles.buttonRow}>
                    <button
                        className={styles.makeModelButton}
                        onClick={() => {
                            if (IS_IN_SEMOBAE) {
                                window.semobaeAPI.openExternal('https://teachablemachine.withgoogle.com');
                            } else {
                                window.open('https://teachablemachine.withgoogle.com', '_blank');
                            }
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Make model"
                            description="Button for creating a model with Teachable Machine"
                            id="gui.prompt.makeModel"
                        />
                    </button>
                    <button
                        className={styles.cancelButton}
                        onClick={props.onCancel}
                    >
                        <FormattedMessage
                            defaultMessage="Cancel"
                            description="Button in prompt for cancelling the dialog"
                            id="gui.prompt.cancel"
                        />
                    </button>
                    <button
                        className={styles.okButton}
                        onClick={props.onOk}
                    >
                        <FormattedMessage
                            defaultMessage="OK"
                            description="Button in prompt for confirming the dialog"
                            id="gui.prompt.ok"
                        />
                    </button>
                </Box>
            </Box>
        </Modal>
    );
}

TeachableMachineComponent.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

export default TeachableMachineComponent;
