import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from '../box/box.jsx';

import styles from './connection-modal.css';
import MarkCodingAutoScanningStep from './mark-coding-auto-scanning-step.jsx';

import PreScanImage from './auto-scanning/prescan.jsx';
import PressButtonImage from './auto-scanning/press-button.jsx';
import NotFoundStepImage from './auto-scanning/not-found.jsx';
import AutoScanningDescription from './auto-scanning/description.jsx';
import AutoScanningButtons from './auto-scanning/buttons.jsx';

const PHASES = keyMirror({
    prescan: null,
    pressbutton: null,
    notfound: null,
    setPort: null
});

const isMarkCoding = props => props.title === 'markCoding';

const AutoScanningStep = props => {
    if (isMarkCoding(props)) {
        return <MarkCodingAutoScanningStep {...props} />;
    }

    return (
        <Box className={styles.body}>
            <Box className={styles.activityArea}>
                <div className={styles.activityAreaInfo}>
                    <div className={styles.centeredRow}>
                        {(props.phase === PHASES.prescan || props.phase === PHASES.setPort) && (
                            <PreScanImage {...props} />
                        )}

                        {props.phase === PHASES.pressbutton && (
                            <PressButtonImage {...props} />
                        )}
                        {props.phase === PHASES.notfound && (
                            <NotFoundStepImage {...props} />
                        )}
                    </div>
                </div>
            </Box>
            <Box className={styles.bottomArea}>
                <AutoScanningDescription {...props} />
                <AutoScanningButtons {...props} />
            </Box>
        </Box>
    );
};

AutoScanningStep.propTypes = {
    connectionTipIconURL: PropTypes.string,
    onRefresh: PropTypes.func,
    onStartScan: PropTypes.func,
    phase: PropTypes.oneOf(Object.keys(PHASES)),
    title: PropTypes.string,
    onShowPortInput: PropTypes.func,
    onChange: PropTypes.func,
    onSetNemoSerialPort: PropTypes.func,
    onNemoConnectionModalClosed: PropTypes.func,
    onClickGoBackButton: PropTypes.func
};

AutoScanningStep.defaultProps = {
    phase: PHASES.prescan
};

export {
    AutoScanningStep as default,
    PHASES
};
