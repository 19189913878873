import React from 'react';
import PropTypes from 'prop-types';
import TeachableMachineComponent from '../components/teachable-machine/teachable-machine.jsx';
import VM from 'scratch-vm';
import bindAll from 'lodash.bindall';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import TmTraining from '../../tm-training/dist/tm-training.js';
import { downloadExtraFile } from '../semobae_utils/semobae_utils.js';
import { setVisible, setHidden } from '../reducers/work-loading-state.js';
import { connect } from 'react-redux';
import JSZip from 'jszip';

const MODEL_JSON_FILE_NAME = 'model.json';
const SAMPLE_JSON_FILE_NAME = 'sampleData.json';
const METADATA_JSON_FILE_NAME = 'metadata.json';
const WEIGHTS_BIN_FILE_NAME = 'weights.bin';

const messages = defineMessages({
    title: {
        id: 'gui.extension.teachableMachine.modalTitle',
        defaultMessage: 'Teachable Machine',
        description: 'teachable machine title'
    },
    placeholder: {
        id: 'gui.extension.teachableMachine.modalPlaceholder',
        defaultMessage: 'Please enter the url',
        description: 'teachable machine placeholder'
    },
    modalDescription: {
        id: 'gui.extension.teachableMachine.modalDescription',
        defaultMessage: 'Teachable machine Model URL',
        description: 'teachable machine modal sub title'
    },
    alertNoURLOrFile: {
        id: 'gui.extension.teachableMachine.alertNoURLOrFile',
        defaultMessage: 'Please enter the URL',
        description: 'alert message for no URL'
    },
    alertInvalidURLOrFile: {
        id: 'gui.extension.teachableMachine.alertInvalidURLOrFile',
        defaultMessage: 'Invalid URL',
        description: 'alert message for invalid URL'
    },
    alertFailedToLoadSampleData: {
        id: 'gui.extension.teachableMachine.alertFailedToLoadSampleData',
        defaultMessage: 'Failed to load sample data',
        description: 'alert message for failed to load sample data'
    },
    alertFailedToLoadModel: {
        id: 'gui.extension.teachableMachine.alertFailedToLoadModel',
        defaultMessage: 'Failed to load model',
        description: 'alert message for failed to load model'
    },
});

const isOfficialModelZip = (zip) => {
    const filelist = Object.keys(zip.files);
    const OFFICIAL_MODEL_FILES = [MODEL_JSON_FILE_NAME, METADATA_JSON_FILE_NAME, WEIGHTS_BIN_FILE_NAME];

    const isOfficial = OFFICIAL_MODEL_FILES.every((filename) => filelist.includes(filename));

    return isOfficial;
}


class CustomTeachableMachine extends React.Component {
    constructor (props) {
        super(props);
        bindAll(
            this,
            [
                'handleCancel',
                'handleChange',
                'handleOk',
                'alertAudioModel',
                'initModel',
                'handleLoadModelZip',
                'handleClearInput',
            ]
        );

        this.state = {
            url: null,
            isAudioModel: false,
            loadedFileZip: null,
        };
    }

    getFormattedTmUrl (unformattedURL) {
        const trimmedURL = unformattedURL.trim();
        return trimmedURL.charAt(trimmedURL.length - 1) === '/' ? trimmedURL : trimmedURL + '/';
    }

    getIsOfficialUrl (formattedURL) {
        try {
            return new URL(formattedURL).host === 'teachablemachine.withgoogle.com';
        } catch (err) {
            console.error('Failed to parse URL: ', err);
            return false;
        }
    }

    async initModelFromOfficialZip (zip) {        
        const modelFile = await zip.file(MODEL_JSON_FILE_NAME).async('text');
        const weightsFile = await zip.file(WEIGHTS_BIN_FILE_NAME).async('blob');
        const metadataFile = await await zip.file(METADATA_JSON_FILE_NAME).async('text');

        this.props.vm.extensionManager.setTeachableMachineBlocksWithFiles(modelFile, weightsFile, metadataFile)
            .then(() => {
                this.props.onDialogClose();
            })
            .catch(error => {
                if (error.message === 'AudioModelError') {
                    this.alertAudioModel();
                    return;
                }
                console.error(error);
                alert(this.props.intl.formatMessage(messages.alertInvalidURLOrFile));
            });
    }

    async handleOk () {
        this.setState({isAudioModel: false});
        if (!this.state.url && !this.state.loadedFileZip) {
            alert(this.props.intl.formatMessage(messages.alertNoURLOrFile));
            return;
        }

        if (this.state.loadedFileZip) {
            this.fileReader = new FileReader();
            this.fileReader.onload = async (e) => {
                try {
                    const arrayBuffer = e.target.result;
                    const zip = await JSZip.loadAsync(arrayBuffer);
                    
                    if (isOfficialModelZip(zip)) {
                        this.initModelFromOfficialZip(zip);
                        return;
                    }

                    const content = await zip.file(MODEL_JSON_FILE_NAME).async('text').catch((err) => {
                        // preserve backward compatibility
                        console.warn('Skip to load model from zip file by', err);
                        return JSON.stringify(null);
                    });
                    const modelData = JSON.parse(content);
                    const content2 = await zip.file(SAMPLE_JSON_FILE_NAME).async('text');
                    const sampleData = JSON.parse(content2);
                    this.initModel(modelData, null, sampleData);
                } catch (err) {
                    console.error('Failed to load sample data from zip file: ', err);
                    alert(this.props.intl.formatMessage(messages.alertFailedToLoadModel));
                    this.props.setHiddenWorkLoader();
                };
            };
            this.props.setVisibleWorkLoader();
            this.fileReader.readAsArrayBuffer(this.state.loadedFileZip);
            return;
        }

        const formattedURL = this.getFormattedTmUrl(this.state.url);
        const isOfficialLink = this.getIsOfficialUrl(formattedURL);

        if (isOfficialLink) {
            this.props.vm.extensionManager.setTeachableMachineBlocksWithUrl(formattedURL)
                .then(() => {
                    this.props.onDialogClose();
                })
                .catch(error => {
                    if (error.message === 'AudioModelError') {
                        this.alertAudioModel();
                        return;
                    }
                    console.error(error);
                    alert(this.props.intl.formatMessage(messages.alertInvalidURLOrFile));
                });
            return;
        }

        const sampleDataURL = this.state.url;
        this.props.vm.runtime.teachableMachineSampleDataURL = sampleDataURL;
        const sampleDataJSON = await downloadExtraFile(sampleDataURL)
            .then(blob => blob.text())
            .catch((err) => {
                console.error(err);
                alert(this.props.intl.formatMessage(messages.alertFailedToLoadSampleData));
            });
        this.initModel(undefined, sampleDataURL, JSON.parse(sampleDataJSON));
    }
    initModel (modelData, sampleDataURL, sampleData) {
        const tm = new TmTraining();
        tm.init()
            .then(async () => {
                this.props.setVisibleWorkLoader();
                if (!modelData) {
                    await tm.loadSampleData(sampleData);
                    modelData = tm.getModelData();
                } else {
                    await tm.load(modelData, sampleData);
                }

                await this.props.vm.extensionManager.setTeachableMachineBlocksWithOfflineModel(
                    modelData, 
                    { 
                        sampleDataURL,
                        sampleData,
                    },
                    tm
                );

                this.props.vm.runtime.emitProjectChanged();
                this.props.onDialogClose();
            }).catch((err) => {
                console.error(err);
                alert(this.props.intl.formatMessage(messages.alertInvalidURLOrFile));
            }).finally(() => {
                this.props.setHiddenWorkLoader();
            });
    }
    handleCancel () {
        this.props.onDialogClose();
        return;
    }
    handleChange (e) {
        this.setState({url: e.target.value});
        return;
    }
    alertAudioModel () {
        this.setState({isAudioModel: true});
    }

    handleLoadModelZip (e) {
        this.setState({
            url: null,
            loadedFileZip: e.target.files[0]
        });
    }

    handleClearInput (fileInput) {
        this.setState({
            url: null,
            loadedFileZip: null
        });

        fileInput.value = '';
    }

    render () {
        const {intl} = this.props;
        return (
            <TeachableMachineComponent
                placeholder={intl.formatMessage(messages.placeholder)}
                label={intl.formatMessage(messages.modalDescription)}
                url={this.state.url ?? ''}
                loadedFileName={this.state.loadedFileZip?.name ?? ''}
                onCancel={this.handleCancel}
                onChange={this.handleChange}
                onOk={this.handleOk}
                title={intl.formatMessage(messages.title)}
                isAudioModel={this.state.isAudioModel}
                vm={this.props.vm}
                onLoadModelZip={this.handleLoadModelZip}
                onClearInput={this.handleClearInput}
            />
        );
    }
}

CustomTeachableMachine.propTypes = {
    vm: PropTypes.PropTypes.instanceOf(VM),
    onDialogClose: PropTypes.func.isRequired,
    intl: intlShape.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onCloseTmTrainingModal: () => {
        dispatch(closeTMTrainingModal());
    },
    setVisibleWorkLoader: () => {
        dispatch(setVisible('teachableMachine', 'Loading...'));
    },
    setHiddenWorkLoader: () => {
        dispatch(setHidden());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CustomTeachableMachine));
