import React from 'react';
import styles from '../connection-modal.css';

import malrangNotFoundImg from '../img/malrang_not_found.png';
import { FormattedMessage } from 'react-intl';
import Box from '../../box/box.jsx';

const NotFoundStepImage = (props) => {
    return (
        <Box className={styles.instructions}>
            {props.title === 'malrang' ? (
                <img
                    className={styles.malrangNotFoundImage}
                    src={malrangNotFoundImg}
                    alt="Malrang Not Found"
                />
            ) : (
                <FormattedMessage
                    defaultMessage="No devices found"
                    description="Text shown when no devices could be found"
                    id="gui.connection.auto-scanning.noPeripheralsFound"
                />
            )}
        </Box>
    );
}; 

export default NotFoundStepImage;