import React from 'react';
import classNames from 'classnames';
import style from '../tm-training-modal.css';
import openNewProjectIcon from '../open-new-project-icon.svg';

const ProjectMenu = ({ onOpenNewProject, onSaveProject, onLoadProject }) => {
    return (
        <div className={style.projectMenu}>
            <button
                className={style.projectMenuItem}
                onClick={onOpenNewProject}
            >
                <img
                    className={style.openNewProjectIcon}
                    src={openNewProjectIcon}
                />
                새 프로젝트
            </button>
            <button
                className={style.projectMenuItem}
                onClick={onSaveProject}
            >
                프로젝트 저장하기
            </button>
            <label className={classNames(style.projectMenuItem, style.projectMenuItemLoad)}>
                <input
                    type='file'
                    style={{display: 'none'}}
                    accept='.zip'
                    onChange={onLoadProject}
                />
                프로젝트 불러오기
            </label>
        </div>
    );
}

export default ProjectMenu;