import React from 'react';
import classNames from 'classnames';
import styles from '../connection-modal.css';

import radarIcon from '../icons/searching.png';
import nemoIcon from '../icons/icon_nemo_black.svg';

const PressButtonImage = (props) => {
    return (
        <React.Fragment>
            <img
                className={classNames(styles.radarBig, styles.radarSpin)}
                src={radarIcon}
            />
            {props.title === 'nemo' && (
                <img
                    className={styles.nemoBluetoothCenteredIcon}
                    src={nemoIcon}
                />
            )}
            <img
                className={styles.connectionTipIcon}
                src={props.connectionTipIconURL}
            />
        </React.Fragment>
    );
}; 

export default PressButtonImage;