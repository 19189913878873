import ScratchBlocks from 'scratch-blocks';
import { disappearingVariableOpcodes } from './utils/malrangHideBlock';

/**
 * Connect scratch blocks with the vm
 * @param {VirtualMachine} vm - The scratch vm
 * @return {ScratchBlocks} ScratchBlocks connected with the vm
 */
export default function (vm) {
    const jsonForMenuBlock = function (name, menuOptionsFn, colors, start) {
        return {
            message0: '%1',
            args0: [
                {
                    type: 'field_dropdown',
                    name: name,
                    options: function () {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            inputsInline: true,
            output: 'String',
            colour: colors.secondary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND
        };
    };

    const jsonForHatBlockMenu = function (hatName, name, menuOptionsFn, colors, start) {
        return {
            message0: hatName,
            args0: [
                {
                    type: 'field_dropdown',
                    name: name,
                    options: function () {
                        return start.concat(menuOptionsFn());
                    }
                }
            ],
            colour: colors.primary,
            colourSecondary: colors.secondary,
            colourTertiary: colors.tertiary,
            extensions: ['shape_hat']
        };
    };


    const jsonForSensingMenus = function (menuOptionsFn) {
        return {
            message0: ScratchBlocks.Msg.SENSING_OF,
            args0: [
                {
                    type: 'field_dropdown',
                    name: 'PROPERTY',
                    options: function () {
                        return menuOptionsFn();
                    }

                },
                {
                    type: 'input_value',
                    name: 'OBJECT'
                }
            ],
            output: true,
            colour: ScratchBlocks.Colours.sensing.primary,
            colourSecondary: ScratchBlocks.Colours.sensing.secondary,
            colourTertiary: ScratchBlocks.Colours.sensing.tertiary,
            outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND
        };
    };

    const soundsMenu = function () {
        let menu = [['', '']];
        if (vm.editingTarget && vm.editingTarget.sprite.sounds.length > 0) {
            menu = vm.editingTarget.sprite.sounds.map(sound => [sound.name, sound.name]);
        }
        menu.push([
            ScratchBlocks.ScratchMsgs.translate('SOUND_RECORD', 'record...'),
            ScratchBlocks.recordSoundCallback
        ]);
        return menu;
    };

    const costumesMenu = function () {
        if (vm.editingTarget && vm.editingTarget.getCostumes().length > 0) {
            return vm.editingTarget.getCostumes().map(costume => [costume.name, costume.name]);
        }
        return [['', '']];
    };

    const backdropsMenu = function () {
        const next = ScratchBlocks.ScratchMsgs.translate('LOOKS_NEXTBACKDROP', 'next backdrop');
        const previous = ScratchBlocks.ScratchMsgs.translate('LOOKS_PREVIOUSBACKDROP', 'previous backdrop');
        const random = ScratchBlocks.ScratchMsgs.translate('LOOKS_RANDOMBACKDROP', 'random backdrop');
        if (vm.runtime.targets[0] && vm.runtime.targets[0].getCostumes().length > 0) {
            return vm.runtime.targets[0].getCostumes().map(costume => [costume.name, costume.name])
                .concat([[next, 'next backdrop'],
                    [previous, 'previous backdrop'],
                    [random, 'random backdrop']]);
        }
        return [['', '']];
    };

    const backdropNamesMenu = function () {
        const stage = vm.runtime.getTargetForStage();
        if (stage && stage.getCostumes().length > 0) {
            return stage.getCostumes().map(costume => [costume.name, costume.name]);
        }
        return [['', '']];
    };

    const spriteMenu = function () {
        const sprites = [];
        for (const targetId in vm.runtime.targets) {
            if (!vm.runtime.targets.hasOwnProperty(targetId)) continue;
            if (vm.runtime.targets[targetId].isOriginal) {
                if (!vm.runtime.targets[targetId].isStage) {
                    if (vm.runtime.targets[targetId] === vm.editingTarget) {
                        continue;
                    }

                    if (vm.runtime.isMissionMode &&
                        vm.runtime.hiddenSpriteIdsFromStage.has(vm.runtime.targets[targetId].id)
                    ) {
                        continue;
                    }

                    sprites.push([vm.runtime.targets[targetId].sprite.name, vm.runtime.targets[targetId].sprite.name]);
                }
            }
        }
        return sprites;
    };

    const cloneMenu = function () {
        if (vm.editingTarget && vm.editingTarget.isStage) {
            const menu = spriteMenu();
            if (menu.length === 0) {
                return [['', '']]; // Empty menu matches Scratch 2 behavior
            }
            return menu;
        }
        const myself = ScratchBlocks.ScratchMsgs.translate('CONTROL_CREATECLONEOF_MYSELF', 'myself');
        return [[myself, '_myself_']].concat(spriteMenu());
    };

    const soundColors = ScratchBlocks.Colours.sounds;

    const looksColors = ScratchBlocks.Colours.looks;

    const motionColors = ScratchBlocks.Colours.motion;

    const sensingColors = ScratchBlocks.Colours.sensing;

    const controlColors = ScratchBlocks.Colours.control;

    const eventColors = ScratchBlocks.Colours.event;

    ScratchBlocks.Blocks.sound_sounds_menu.init = function () {
        const json = jsonForMenuBlock('SOUND_MENU', soundsMenu, soundColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_costume.init = function () {
        const json = jsonForMenuBlock('COSTUME', costumesMenu, looksColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.looks_backdrops.init = function () {
        const json = jsonForMenuBlock('BACKDROP', backdropsMenu, looksColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.event_whenbackdropswitchesto.init = function () {
        const json = jsonForHatBlockMenu(
            ScratchBlocks.Msg.EVENT_WHENBACKDROPSWITCHESTO,
            'BACKDROP', backdropNamesMenu, eventColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_pointtowards_menu.init = function () {
        const mouse = ScratchBlocks.ScratchMsgs.translate('MOTION_POINTTOWARDS_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('TOWARDS', spriteMenu, motionColors, [
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_goto_menu.init = function () {
        const random = ScratchBlocks.ScratchMsgs.translate('MOTION_GOTO_RANDOM', 'random position');
        const mouse = ScratchBlocks.ScratchMsgs.translate('MOTION_GOTO_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('TO', spriteMenu, motionColors, [
            [random, '_random_'],
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.motion_glideto_menu.init = function () {
        const random = ScratchBlocks.ScratchMsgs.translate('MOTION_GLIDETO_RANDOM', 'random position');
        const mouse = ScratchBlocks.ScratchMsgs.translate('MOTION_GLIDETO_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('TO', spriteMenu, motionColors, [
            [random, '_random_'],
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_of_sprite_menu.init = function () {
        const myself = ScratchBlocks.ScratchMsgs.translate('SENSING_OF_MYSELF', 'myself');
        const json = jsonForMenuBlock('OBJECT', spriteMenu, sensingColors, [
            [myself, 'myself']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_of_object_menu.init = function () {
        const stage = ScratchBlocks.ScratchMsgs.translate('SENSING_OF_STAGE', 'Stage');
        const json = jsonForMenuBlock('OBJECT', spriteMenu, sensingColors, [
            [stage, '_stage_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_of.init = function () {
        const blockId = this.id;
        const blockType = this.type;

        // Get the sensing_of block from vm.
        let defaultSensingOfBlock;
        const blocks = vm.runtime.flyoutBlocks._blocks;
        Object.keys(blocks).forEach(id => {
            const block = blocks[id];
            if (id === blockType || (block && block.opcode === blockType)) {
                defaultSensingOfBlock = block;
            }
        });

        // Function that fills in menu for the first input in the sensing block.
        // Called every time it opens since it depends on the values in the other block input.
        const menuFn = function () {
            const stageOptions = [
                [ScratchBlocks.Msg.SENSING_OF_BACKDROPNUMBER, 'backdrop #'],
                [ScratchBlocks.Msg.SENSING_OF_BACKDROPNAME, 'backdrop name'],
                [ScratchBlocks.Msg.SENSING_OF_VOLUME, 'volume']
            ];
            const spriteOptions = [
                [ScratchBlocks.Msg.SENSING_OF_XPOSITION, 'x position'],
                [ScratchBlocks.Msg.SENSING_OF_YPOSITION, 'y position'],
                [ScratchBlocks.Msg.SENSING_OF_DIRECTION, 'direction'],
                [ScratchBlocks.Msg.SENSING_OF_COSTUMENUMBER, 'costume #'],
                [ScratchBlocks.Msg.SENSING_OF_COSTUMENAME, 'costume name'],
                [ScratchBlocks.Msg.SENSING_OF_SIZE, 'size'],
                [ScratchBlocks.Msg.SENSING_OF_VOLUME, 'volume'],
                [ScratchBlocks.Msg.SENSING_OF_BUBBLE, 'bubble'],
                [ScratchBlocks.Msg.SENSING_OF_CLONECOUNT, 'clone count'],
                [ScratchBlocks.Msg.SENSING_OF_BLOCKCOUNT, 'block count']
            ];
            if (vm.editingTarget) {
                let lookupBlocks = vm.editingTarget.blocks;
                let sensingOfBlock = lookupBlocks.getBlock(blockId);

                // The block doesn't exist, but should be in the flyout. Look there.
                if (!sensingOfBlock) {
                    sensingOfBlock = vm.runtime.flyoutBlocks.getBlock(blockId) || defaultSensingOfBlock;
                    // If we still don't have a block, just return an empty list . This happens during
                    // scratch blocks construction.
                    if (!sensingOfBlock) {
                        return [['', '']];
                    }
                    // The block was in the flyout so look up future block info there.
                    lookupBlocks = vm.runtime.flyoutBlocks;
                }
                const sort = function (options) {
                    options.sort(ScratchBlocks.scratchBlocksUtils.compareStrings);
                };
                // Get all the stage variables (no lists) so we can add them to menu when the stage is selected.
                const stageVariableOptions = vm.runtime.getTargetForStage().getAllVariableNamesInScopeByType('');
                sort(stageVariableOptions);
                const stageVariableMenuItems = stageVariableOptions.map(variable => [variable, variable]);
                if (sensingOfBlock.inputs.OBJECT.shadow !== sensingOfBlock.inputs.OBJECT.block) {
                    // There's a block dropped on top of the menu. It'd be nice to evaluate it and
                    // return the correct list, but that is tricky. Scratch2 just returns stage options
                    // so just do that here too.
                    return stageOptions.concat(stageVariableMenuItems);
                }
                const menuBlock = lookupBlocks.getBlock(sensingOfBlock.inputs.OBJECT.shadow);
                const selectedItem = menuBlock.fields.OBJECT.value;
                if (selectedItem === '_stage_') {
                    return stageOptions.concat(stageVariableMenuItems);
                }
                // Get all the local variables (no lists) and add them to the menu.
                const target = vm.runtime.getSpriteTargetByName(selectedItem);
                let spriteVariableOptions = [];
                // The target should exist, but there are ways for it not to (e.g. #4203).
                if (target) {
                    spriteVariableOptions = target.getAllVariableNamesInScopeByType('', true);
                    sort(spriteVariableOptions);
                }
                const spriteVariableMenuItems = spriteVariableOptions.map(variable => [variable, variable]);
                return spriteOptions.concat(spriteVariableMenuItems);
            }
            return [['', '']];
        };

        const json = jsonForSensingMenus(menuFn);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_distancetomenu.init = function () {
        const mouse = ScratchBlocks.ScratchMsgs.translate('SENSING_DISTANCETO_POINTER', 'mouse-pointer');
        const json = jsonForMenuBlock('DISTANCETOMENU', spriteMenu, sensingColors, [
            [mouse, '_mouse_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.sensing_touchingobjectmenu.init = function () {
        const mouse = ScratchBlocks.ScratchMsgs.translate('SENSING_TOUCHINGOBJECT_POINTER', 'mouse-pointer');
        const edge = ScratchBlocks.ScratchMsgs.translate('SENSING_TOUCHINGOBJECT_EDGE', 'edge');
        const json = jsonForMenuBlock('TOUCHINGOBJECTMENU', spriteMenu, sensingColors, [
            [mouse, '_mouse_'],
            [edge, '_edge_']
        ]);
        this.jsonInit(json);
    };

    ScratchBlocks.Blocks.control_create_clone_of_menu.init = function () {
        const json = jsonForMenuBlock('CLONE_OPTION', cloneMenu, controlColors, []);
        this.jsonInit(json);
    };

    ScratchBlocks.VerticalFlyout.getCheckboxState = function (blockId) {
        const monitoredBlock = vm.runtime.monitorBlocks._blocks[blockId];
        return monitoredBlock ? monitoredBlock.isMonitored : false;
    };

    ScratchBlocks.VerticalFlyout.prototype.setAutoClose = function (optAutoClose) {
        this.autoClose = optAutoClose;
        const workspace = this.parentToolbox_.workspace_;
        const {viewLeft: oldViewLeft} = workspace.getMetrics();
        workspace.resize();
        const gap = this.getWidth() * (optAutoClose ? -1 : 1);
        workspace.scrollbar.hScroll.resize({
            ...workspace.getMetrics(),
            viewLeft: oldViewLeft + gap
        });
    };

    ScratchBlocks.FlyoutExtensionCategoryHeader.getExtensionState = function (extensionId) {
        if (vm.getPeripheralIsConnected(extensionId)) {
            return ScratchBlocks.StatusButtonState.READY;
        }
        return ScratchBlocks.StatusButtonState.NOT_READY;
    };

    ScratchBlocks.FlyoutExtensionCategoryHeader.getDeviceState = function (extensionId) {
        const droneExtensionIds = ['codingDrone', 'codingRider', 'skykick'];

        if (!droneExtensionIds.includes(extensionId)) {
            return null;
        }

        if (vm.getDevicePaired(extensionId)) {
            return ScratchBlocks.DevicePairingStatus.PAIRING;
        }

        return ScratchBlocks.DevicePairingStatus.NOT_PAIRING;
    };

    ScratchBlocks.FieldNote.playNote_ = function (noteNum, extensionId) {
        vm.runtime.emit('PLAY_NOTE', noteNum, extensionId);
    };

    // Use a collator's compare instead of localeCompare which internally
    // creates a collator. Using this is a lot faster in browsers that create a
    // collator for every localeCompare call.
    const collator = new Intl.Collator([], {
        sensitivity: 'base',
        numeric: true
    });
    ScratchBlocks.scratchBlocksUtils.compareStrings = function (str1, str2) {
        return collator.compare(str1, str2);
    };

    // Blocks wants to know if 3D CSS transforms are supported. The cross
    // section of browsers Scratch supports and browsers that support 3D CSS
    // transforms will make the return always true.
    //
    // Shortcutting to true lets us skip an expensive style recalculation when
    // first loading the Scratch editor.
    ScratchBlocks.utils.is3dSupported = function () {
        return true;
    };

    if (ScratchBlocks.Blocks.malrang_of) {
        const defaultExtensionColors = {
            primary: '#0FBD8C',
            secondary: '#0DA57A',
            tertiary: '#0B8E69'
        };

        ScratchBlocks.Blocks.malrang_menu_target.init = function () {
            const json = jsonForMenuBlock('OBJECT', spriteMenu, defaultExtensionColors, [
                ['무대', '_stage_']
            ]);
            this.jsonInit(json);
        };

        ScratchBlocks.Blocks.malrang_of.init = function () {
            const blockId = this.id;
            const blockType = this.type;

            // Get the sensing_of block from vm.
            let defaultMalrangOfBlock;
            const blocks = vm.runtime.flyoutBlocks._blocks;
            Object.keys(blocks).forEach(id => {
                const block = blocks[id];
                if (id === blockType || (block && block.opcode === blockType)) {
                    defaultMalrangOfBlock = block;
                }
            });

            // Function that fills in menu for the first input in the sensing block.
            // Called every time it opens since it depends on the values in the other block input.
            const menuFn = function () {
                const stageOptions = [
                    ['배경 번호 #', 'backdrop #'],
                    ['배경 이름', 'backdrop name'],
                    ['음량', 'volume']
                ];
                const spriteOptions = [
                    ['x좌표', 'x'],
                    ['y좌표', 'y'],
                    ['방향', 'direction'],
                    ['모양 #', 'costume #'],
                    ['모양 이름', 'costume name'],
                    ['크기', 'size'],
                    ['음량', 'volume'],
                    ['말풍선', 'bubble'],
                    ['복제본 수', 'clone count'],
                    ['블록 수', 'block count']
                ];
                if (vm.editingTarget) {
                    let lookupBlocks = vm.editingTarget.blocks;
                    let malrangOfBlock = lookupBlocks.getBlock(blockId);

                    // The block doesn't exist, but should be in the flyout. Look there.
                    if (!malrangOfBlock) {
                        malrangOfBlock = vm.runtime.flyoutBlocks.getBlock(blockId) || defaultMalrangOfBlock;
                        // If we still don't have a block, just return an empty list . This happens during
                        // scratch blocks construction.
                        if (!malrangOfBlock) {
                            return [['배경 번호 #', 'backdrop #']];
                        }
                        // The block was in the flyout so look up future block info there.
                        lookupBlocks = vm.runtime.flyoutBlocks;
                    }
                    const sort = function (options) {
                        options.sort(ScratchBlocks.scratchBlocksUtils.compareStrings);
                    };
                    // Get all the stage variables (no lists) so we can add them to menu when the stage is selected.
                    const stageVariableOptions = vm.runtime.getTargetForStage().getAllVariableNamesInScopeByType('');
                    sort(stageVariableOptions);
                    const stageVariableMenuItems = stageVariableOptions.map(variable => [variable, variable]);
                    if (malrangOfBlock.inputs.OBJECT.shadow !== malrangOfBlock.inputs.OBJECT.block) {
                        // There's a block dropped on top of the menu. It'd be nice to evaluate it and
                        // return the correct list, but that is tricky. Scratch2 just returns stage options
                        // so just do that here too.
                        return stageOptions.concat(stageVariableMenuItems);
                    }
                    const menuBlock = lookupBlocks.getBlock(malrangOfBlock.inputs.OBJECT.shadow);
                    const selectedItem = menuBlock.fields.OBJECT.value;
                    if (selectedItem === '_stage_') {
                        return stageOptions.concat(stageVariableMenuItems);
                    }
                    // Get all the local variables (no lists) and add them to the menu.
                    const target = vm.runtime.getSpriteTargetByName(selectedItem);
                    let spriteVariableOptions = [];
                    // The target should exist, but there are ways for it not to (e.g. #4203).
                    if (target) {
                        spriteVariableOptions = target.getAllVariableNamesInScopeByType('', true);
                        sort(spriteVariableOptions);
                    }
                    const spriteVariableMenuItems = spriteVariableOptions.map(variable => [variable, variable]);
                    return spriteOptions.concat(spriteVariableMenuItems);
                }
                return [['', '']];
            };

            const json = {
                message0: '%1 of %2',
                args0: [
                    {
                        type: 'field_dropdown',
                        name: 'PROPERTY',
                        options: function () {
                            return menuFn();
                        }

                    },
                    {
                        type: 'input_value',
                        name: 'OBJECT'
                    }
                ],
                output: true,
                colour: defaultExtensionColors.primary,
                colourSecondary: defaultExtensionColors.secondary,
                colourTertiary: defaultExtensionColors.tertiary,
                outputShape: ScratchBlocks.OUTPUT_SHAPE_ROUND
            };
            this.jsonInit(json);
        };
    }

    if (ScratchBlocks.Blocks.generativeAi_generateSpriteCostume) {
        const generativeAiColors = {
            primary: '#1b969a',
            secondary: '#157376',
            tertiary: '#157376'
        };

        ScratchBlocks.Blocks.generativeAi_menu_sprites.init = function () {
            const menuFn = function () {
                const sprites = [];
                if (!vm.editingTarget.isStage) {
                    sprites.push([vm.editingTarget.sprite.name, vm.editingTarget.sprite.name]);
                }
                return sprites.concat(spriteMenu());
            };
            const json = jsonForMenuBlock('SPRITES', menuFn, generativeAiColors, []);
            this.jsonInit(json);
        };
    }

    if (ScratchBlocks.Blocks.aruco_isArucoTouchingObject) {
        const arucoColors = {
            primary: '#0FBD8C',
            secondary: '#0DA57A',
            tertiary: '#0B8E69'
        };

        ScratchBlocks.Blocks.aruco_menu_sprites.init = function () {
            const menuFn = function () {
                const sprites = [];
                if (!vm.editingTarget.isStage) {
                    sprites.push([vm.editingTarget.sprite.name, vm.editingTarget.sprite.name]);
                }
                return sprites.concat(spriteMenu());
            };
            
            const json = jsonForMenuBlock('SPRITE', menuFn, arucoColors, []);
            this.jsonInit(json);
        };
    }
    
    const dataAddSep = ScratchBlocks.DataCategory.addSep;
    ScratchBlocks.DataCategory.addSep = function (...params) {
        if (
            (vm.runtime.isMissionMode || vm.runtime.openMalrangModal) &&
            vm.runtime.filterBlockCategories
        ) {
            return;
        }
        dataAddSep(...params);
    };

    const dataAddCreateButton = ScratchBlocks.DataCategory.addCreateButton;
    ScratchBlocks.DataCategory.addCreateButton = function (...params) {
        if (
            (vm.runtime.isMissionMode || vm.runtime.openMalrangModal) &&
            vm.runtime.filterBlockCategories
        ) {
            return;
        }
        dataAddCreateButton(...params);
    };

    const addDataBlock = ScratchBlocks.DataCategory.addBlock;
    const dataAddBlockCallback = function (...params) {
        const opcode = params[2];

        if (!vm.runtime.isMissionMode) {
            addDataBlock(...params);
            return;
        }

        if ((opcode !== 'data_variable' && opcode !== 'data_listcontents') &&
            vm.runtime.visibleBlocks.data?.includes(opcode)) {
            addDataBlock(...params);
            return;
        }

        if ((opcode === 'data_variable' || opcode === 'data_listcontents') && 
            vm.workspaceVariableNames?.includes(params[1].name)) {
            addDataBlock(...params);
            return;
        }

        if (!vm.runtime.filterBlockCategories) {
            addDataBlock(...params);
            return;
        }
    };

    function extractBlockAndType(xmlString) {
        const arr = xmlString.split('');
        let stack = [];
        let result = [];
    
        for (const index in arr) {
            const str = arr[index];
            const nextStr = arr[+index + 1];
    
            if (str === '<' && nextStr === '/') {
                const start = stack.pop();
                const end = +index;
                result.push(xmlString.substring(start, end + 1));
            } else if (str === '<') {
                stack.push(+index);
            }
        }
    
        return result
                .filter((block) => block.startsWith('<block') && block.includes('type="data_'))
                .map((block) => `${block}/block>`)
                .map((block) => {
                    const nextTagIndex = block.indexOf('<next>');
    
                    if (nextTagIndex === -1) {
                        return block;
                    }
    
                    const nextTagEndIndex = block.lastIndexOf('</next>');
                
                    return block.substring(0, nextTagIndex) + block.substring(nextTagEndIndex + 7);
                })
                .map((block) => {
                    const start = block.indexOf('<block', 6);
                    const end = block.lastIndexOf('</block>', block.length - 9);
                    
                    if (start !== -1) {
                        return block.substring(0, start) + block.substring(end + 8, block.length);
                    }
    
                    return block;
                })
                .map((block) => {
                    return {
                        innerHTML: block,
                        type: block.match(/type="([^"]*)"/)[1],
                    };
                })
                .map(({innerHTML, type}) => {
                    const blockStartIndex = innerHTML.indexOf('>');
                    const blockEndIndex = innerHTML.lastIndexOf('<');
                    const blockString = innerHTML.substring(blockStartIndex + 1, blockEndIndex);
                    return {
                        innerHTML: blockString,
                        type,
                    };
                });
    }

    const openMalrangDataAddBlockCallback = function (...params) {
        const opcode = params[2];

        if (
            vm.runtime.openMalrangModal &&
            !disappearingVariableOpcodes.includes(opcode) &&
            vm.runtime.allVisibleBlocks.data?.includes(opcode)
        ) {
            addDataBlock(...params);
            return;
        }

        const targets = Object.values(vm.runtime.targets);

        const xmlString = vm.runtime.cachedWorkspaceXMLInAllTargets || targets.map(target => target.blocks.toXML({})).join(' ');

        const myBlocksIntargets = extractBlockAndType(xmlString);

        myBlocksIntargets.forEach(xmlString => {
            if (!xmlString) {
                return;
            }

            if (!disappearingVariableOpcodes.includes(xmlString.type)) {
                return;
            }

            const newBlock = document.createElement('block');
            newBlock.setAttribute('type', xmlString.type);
            newBlock.setAttribute('gap', '16');
            newBlock.innerHTML = xmlString.innerHTML;

            if (params[0].find(block => block.innerText === newBlock.innerText && block.attributes.type.nodeValue === newBlock.attributes.type.nodeValue)) {
                return;
            }

            params[0].push(newBlock);
        });
    };

    ScratchBlocks.DataCategory.addBlock = function (...params) {
        return vm.runtime.openMalrangModal ? openMalrangDataAddBlockCallback(...params) : dataAddBlockCallback(...params);
    };

    function extractMutations(xmlString) {
        const blockRegex = /<block[^>]*type="procedures_call"[^>]*>(.*?)<\/block>/gs;
        const mutationRegex = /<mutation[^>]*proccode="([^"]*)"[^>]*>(.*?)<\/mutation>/gs;
        const mutations = [];
        const proccodes = new Set();
        let blockMatch;
    
        while ((blockMatch = blockRegex.exec(xmlString)) !== null) {
            const blockContent = blockMatch[1];
            let mutationMatch;
            
            while ((mutationMatch = mutationRegex.exec(blockContent)) !== null) {
                const proccode = mutationMatch[1];
                const mutationTag = mutationMatch[0];
                
                if (!proccodes.has(proccode)) {
                    proccodes.add(proccode);
                    mutations.push(mutationTag);
                }
            }
        }
    
        return mutations;
    }
    
    const procedureFlyoutCategory = ScratchBlocks.Procedures.flyoutCategory;

    const flyoutCategoryCallback = function (...params) {
        return procedureFlyoutCategory(...params).filter(xml => {
            const procCode = xml.children[0]?.attributes?.proccode.nodeValue;
            if (
                (vm.runtime.isMissionMode || vm.runtime.openMalrangModal) &&
                vm.runtime.filterBlockCategories &&
                procCode
            ) {
                return vm.workspaceProcCodes.includes(procCode);
            }
            return true;
        });
    };

    const glowStack = ScratchBlocks.WorkspaceSvg.prototype.glowStack;
    ScratchBlocks.WorkspaceSvg.prototype.glowStack = function (...params) {
        const [id] = params;
        const block = this.getBlockById(id);
        if (vm.runtime.isMissionMode && !block) {
            return;
        }
        glowStack.apply(this, params);
    };

    const allProcedureMutations = ScratchBlocks.Procedures.allProcedureMutations;
    ScratchBlocks.Procedures.allProcedureMutations = function(...args) {
        if (!vm.runtime.isMissionMode) {
            return allProcedureMutations.apply(this, args);
        }

        const visibleProcCodes = vm.workspaceProcCodes;
        const procedureChildBlockIds = vm.runtime.getProcedureChildBlockIds();
        const procedureBlocks = {};

        const mutations = [];

        Object.values(vm.editingTarget.blocks._blocks).filter(block => {
            return procedureChildBlockIds[block.id];
        }).forEach(block => {
            procedureBlocks[block.id] = block;
        });

        const originTargetBlocks = vm.editingTarget.blocks._blocks;
        vm.editingTarget.blocks._blocks = procedureBlocks;
        const procedureXML = '<xml>' + vm.editingTarget.blocks.toXML(vm.editingTarget.comments) + '</xml>';
        const procedureDOM = ScratchBlocks.Xml.textToDom(procedureXML);

        const procedureDefinitions = Array.from(procedureDOM.children);

        procedureDefinitions.forEach(procedure => {
            const procedureMutations = Array.from(procedure.getElementsByTagName('mutation'));

            procedureMutations.forEach(mutation => {
                const proccode = mutation.getAttribute('proccode');

                if (visibleProcCodes.includes(proccode)) {
                    mutations.push(mutation);
                }
            });
        });

        vm.editingTarget.blocks._blocks = originTargetBlocks;
        return mutations;
    };

    const openMalrangFlyoutCategoryCallback = function (...params) {
        const targets = Object.values(vm.runtime.targets);

        const xmlString = vm.runtime.cachedWorkspaceXMLInAllTargets || targets.map(target => target.blocks.toXML({})).join(' ');

        const mutationsIntargets = extractMutations(xmlString);

        const newBlocks = mutationsIntargets.map(xmlString => {
            if (!xmlString) {
                return;
            }

            const newBlock = document.createElement('block');
            newBlock.setAttribute('type', 'procedures_call');
            newBlock.setAttribute('gap', '16');
            newBlock.innerHTML = xmlString;
            return newBlock;
        });

        return newBlocks;
    };

    ScratchBlocks.Procedures.flyoutCategory = function (...params) {
        return vm.runtime.openMalrangModal ? openMalrangFlyoutCategoryCallback(...params) : flyoutCategoryCallback(...params);
    };
    
    const procedureAddCreateButton = ScratchBlocks.Procedures.addCreateButton_;
    ScratchBlocks.Procedures.addCreateButton_ = function (...params) {
        if (
            (vm.runtime.isMissionMode || vm.runtime.openMalrangModal) &&
            vm.runtime.filterBlockCategories
        ) {
            return;
        }
        procedureAddCreateButton(...params);
    };

    const createCheckbox_ = ScratchBlocks.VerticalFlyout.prototype.createCheckbox_;
    ScratchBlocks.VerticalFlyout.prototype.createCheckbox_ = function (...params) {
        if (vm.runtime.openMalrangModal) {
            return;
        }
        createCheckbox_.apply(this, params);
    };

    const workspaceContextMenuShow = ScratchBlocks.ContextMenu.show;
    ScratchBlocks.ContextMenu.show = function (...params) {
        if (vm.runtime.isMissionMode) {
            const deleteBlockMenuIndex = 4;
            const menus = params[1];
            menus.splice(deleteBlockMenuIndex, 1);
        }

        workspaceContextMenuShow(...params);
    };

    const BROADCAST_MENU_BLACKLIST = [
        '튜토리얼 미션 성공',
        '튜토리얼 미션 실패'
    ]

    const dropdownCreate = ScratchBlocks.FieldVariable.dropdownCreate;
    ScratchBlocks.FieldVariable.dropdownCreate = function (...params) {
        let options = dropdownCreate.apply(this, params);
        const [variableType] = this.getVariableTypes_();
        if (
            vm.runtime.isMissionMode &&
            variableType === ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE
        ) {
            options = options.filter(option => {
                const [text] = option;
                return !BROADCAST_MENU_BLACKLIST.includes(text);
            });
        }
        return options;
    };

    const initFlyoutBroadcast = ScratchBlocks.FieldVariable.prototype.initFlyoutBroadcast_;
    ScratchBlocks.FieldVariable.prototype.initFlyoutBroadcast_ = function (...params) {
        if (vm.runtime.isMissionMode) {
            const [workspace] = params;
            const broadcastMsgType = ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE;
            const broadcastVars = workspace.getVariablesOfType(broadcastMsgType);
            const broadcastVar = broadcastVars.filter(variable => {
                return !BROADCAST_MENU_BLACKLIST.includes(variable.name);
            })[0];
            return broadcastVar;
        } else {
            return initFlyoutBroadcast.apply(this, params);
        }
    };

    return ScratchBlocks;
}
