import updateTechnicHex from '../img/technic-update-hex.png';
import updateMalrangHex from '../img/malrang-update-hex.png';
import updateNemoHex from '../img/nemo-update-hex.png';

export const HEX_IMAGES = Object.freeze({
    technic: updateTechnicHex,
    malrang: updateMalrangHex,
    nemo: updateNemoHex,
    default: updateMalrangHex
});

export const PROGRESS_STATUS = Object.freeze({
    IDLE: 'progress_idle',
    IN_PROGRESS: 'progress_inprogress',
    DONE: 'progress_done'
});

export const HEX_BUTTON_TEXT = Object.freeze({
    nemo: {
        [PROGRESS_STATUS.IDLE]: '업데이트 시작',
        [PROGRESS_STATUS.IN_PROGRESS]: '',
        [PROGRESS_STATUS.DONE]: '완료'
    },
    default: {
        [PROGRESS_STATUS.IDLE]: '활성화 시작',
        [PROGRESS_STATUS.IN_PROGRESS]: '활성화 중...',
        [PROGRESS_STATUS.DONE]: '활성화 완료'
    }
});

export const HEX_DESCRIPTION = Object.freeze({
    technic: {
        [PROGRESS_STATUS.IDLE]: 'CPU 블록을 사용하려면 아래 \'활성화 시작\' 버튼을 클릭하세요',
        [PROGRESS_STATUS.IN_PROGRESS]: '스크래치 기능 활성화 모드로 변환중입니다.',
        [PROGRESS_STATUS.DONE]: '스크래치 기능 활성화 모드 변환이 완료되었습니다. 활성화 완료 버튼을 눌러주세요,\n테크닉 연결 시 R버튼과 S버튼을 다시 눌러주세요.'
    },
    nemo: {
        [PROGRESS_STATUS.IDLE]: '업데이트 시작을 클릭하면 네모보드 펌웨어 업데이트가 진행됩니다.',
        [PROGRESS_STATUS.IN_PROGRESS]: '네모보드 펌웨어를 업데이트 하는 중입니다.',
        [PROGRESS_STATUS.DONE]: '네모보드 펌웨어 업데이트가 완료되었습니다.'
    },
    default: {
        [PROGRESS_STATUS.IDLE]: 'CPU 블록을 사용하려면 아래 \'활성화 시작\' 버튼을 클릭하세요',
        [PROGRESS_STATUS.IN_PROGRESS]: '활성화 중...',
        [PROGRESS_STATUS.DONE]: '활성화 완료'
    }
});