import React from 'react';
import classNames from 'classnames';
import Modal from '../../containers/modal.jsx';
import style from './tm-training-modal.css';
import TrainingResult from './training-result/training-result.jsx';
import ProjectMenu from './project-menu/project-menu.jsx';
import ClassesContainer from './class-container/classes-container.jsx';
import { connect } from 'react-redux';
import { setHidden, setVisible } from '../../reducers/work-loading-state.js';

class TmTrainingModalComponent extends React.Component {
    constructor (props) {
        super(props);
        this.resultContainerRef = React.createRef();
        this.tmModalContainerRef = React.createRef();
    }

    render () {
        const {
            onOpenCameraViewOfClass,
            onCloseCameraViewOfClass,
            tmTrainingInstance,
            onCloseTmTrainingModal,
            onDeviceChange,
            onApplyModel,
            onSaveSample,
            isCameraViewOpened,
            classIds,
            onAddNewClass,
            onDeleteSample,
            onClassMenuToggle,
            isClassMenuOpened,
            clearClass,
            deleteClass,
            canDeleteAnyClass,
            isProjectMenuOpen,
            onOpenProjectMenu,
            onCloseProjectMenu,
            onOpenNewProject,
            uploadedSampleLink,
            onAddNewSample,
            shouldSampleChangesUploaded,
            onSaveProject,
            onLoadProject,
            setVisibleWorkLoader,
            setHiddenWorkLoader
        } = this.props;
        const CONTENT_TITLE = '티처블머신 모델 학습';

        const onStartTraining = () => {
            setVisibleWorkLoader();
            tmTrainingInstance.trainAllClasses()
                .then(() => {
                    setHiddenWorkLoader();
                })
                .catch((err) => {
                    alert('학습에 실패했습니다.');
                    console.error(err);
                    setHiddenWorkLoader();
                });
        }

        return (<Modal
            fullScreen
            contentLabel={CONTENT_TITLE}
            onRequestClose={onCloseTmTrainingModal}
        >
            <div className={style.container} ref={this.tmModalContainerRef}>
                <div
                    className={classNames(style.menuButton, isProjectMenuOpen && style.active)}
                    onClick={isProjectMenuOpen ? onCloseProjectMenu : onOpenProjectMenu}
                >
                    <div className={style.hamburgerLines}>
                        <span className={style.line} />
                        <span className={style.line} />
                        <span className={style.line} />
                    </div>
                    메뉴
                </div>
                {isProjectMenuOpen && (
                    <ProjectMenu
                        onLoadProject={onLoadProject}
                        onOpenNewProject={onOpenNewProject}
                        onSaveProject={onSaveProject}
                    />
                )}
                <div className={style.columnContainer}>
                    <div className={style.classContainerColumn}>
                        <ClassesContainer
                            canDeleteAnyClass={canDeleteAnyClass}
                            classIds={classIds}
                            clearClass={clearClass}
                            deleteClass={deleteClass}
                            isCameraViewOpened={isCameraViewOpened}
                            isClassMenuOpened={isClassMenuOpened}
                            onAddNewClass={onAddNewClass}
                            onAddNewSample={onAddNewSample}
                            onCloseCameraViewOfClass={onCloseCameraViewOfClass}
                            onDeleteSample={onDeleteSample}
                            onDeviceChange={onDeviceChange}
                            onOpenCameraViewOfClass={onOpenCameraViewOfClass}
                            onClassMenuToggle={onClassMenuToggle}
                            resultContainerRef={this.resultContainerRef}
                            tmModalContainerRef={this.tmModalContainerRef}
                            tmTrainingInstance={tmTrainingInstance}
                        />
                    </div>
                    <div className={style.trainingResultColumn} >
                        <button
                            onClick={onStartTraining}
                        >
                            학습하기
                        </button>
                    </div>
                    <div className={style.trainingResultColumn}>
                        <TrainingResult
                            video={tmTrainingInstance.video}
                            onDeviceChange={onDeviceChange}
                            onApplyModel={onApplyModel}
                            onSaveSample={onSaveSample}
                            tmTrainingInstance={tmTrainingInstance}
                            uploadedSampleLink={uploadedSampleLink}
                            shouldSampleChangesUploaded={shouldSampleChangesUploaded}
                            resultContainerRef={this.resultContainerRef}
                        />
                    </div>
                </div>
            </div>
        </Modal>);
    };
}

export default TmTrainingModalComponent;