import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../connection-modal.css';
import { PHASES } from '../auto-scanning-step.jsx';
import Box from '../../box/box.jsx';
import classNames from 'classnames';

const PRESSBUTTON_STEP_MESSAGE = {
    nemo: (
        <>
            <FormattedMessage
                defaultMessage="Search takes a long time."
                description="Search takes a long time"
                id="gui.connection.auto-scanning.search-takes-a-long-time"
            />
            <br />
            <FormattedMessage
                defaultMessage="Press the button on your device."
                description="Prompt for pushing the button on the device"
                id="gui.connection.auto-scanning.pressbutton"
            />
        </>
    ),
    drone: (
        <FormattedMessage
            defaultMessage="Connecting to the drone controller."
            description="Connecting to the drone controller"
            id="gui.connection.auto-scanning.scanning.drone"
        />
    ),
    default: (
        <FormattedMessage
            defaultMessage="Press the button on your device."
            description="Prompt for pushing the button on the device"
            id="gui.connection.auto-scanning.pressbutton"
        />
    )
};

const getPressButtonStepMessage = (title) => {
    switch (title) {
        case 'nemo':
            return PRESSBUTTON_STEP_MESSAGE.nemo;
        case 'codingDrone':
        case 'codingRider':
        case 'skykick':
            return PRESSBUTTON_STEP_MESSAGE.drone;
        default:
            return PRESSBUTTON_STEP_MESSAGE.default;
    };
};

const AutoScanningDescription = (props) => {
    return (
        <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
            {props.phase === PHASES.prescan && (
                <FormattedMessage
                    defaultMessage="Have your device nearby, then begin searching."
                    description="Prompt for beginning the search"
                    id="gui.connection.auto-scanning.prescan"
                />
            )}
            {props.phase === PHASES.pressbutton && (
                getPressButtonStepMessage(props.title)
            )}
        </Box>
    );
};

export default AutoScanningDescription;