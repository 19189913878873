import React from 'react';
import classNames from 'classnames';
import styles from '../connection-modal.css';

import Carousel from '../../carousel/Carousel.jsx';
import bluetoothIcon from '../icons/bluetooth-white.svg';
import nemoImg from '../img/nemo.png';
import malrangBlueToothConnect from '../img/malrang_bluetooth_connect.png';
import malrangUsbConnect from '../img/malrang_usb_connect.png';
import technicBlueToothConnect from '../img/technic_bluetooth_connect.png';
import technicUsbConnect from '../img/technic_usb_connect.png';
import droneKoreanConnectDialog from '../img/drone_dialog_korean.png';
import droneEnglishConnectDialog from '../img/drone_dialog_english.png';
import droneJapaneseConnectDialog from '../img/drone_dialog_japanese.png';
import radarIcon from '../icons/searching.png';

import { isByrobotDroneExtension } from '../../../lib/isByrobotDroneEx.js';

const droneConnectionSrc = {
    ko: droneKoreanConnectDialog,
    en: droneEnglishConnectDialog,
    ja: droneJapaneseConnectDialog
};

const imgSlideDuration = 4500;

const bluetoothIcons = {
    nemo: ''
};

const connectionImgs = {
    nemo: nemoImg,
    malrangBlueTooth: malrangBlueToothConnect,
    malrangUsb: malrangUsbConnect,
    technicBlueTooth: technicBlueToothConnect,
    technicUsb: technicUsbConnect,
};

const PreScanImage = (props) => {
   return (
    <React.Fragment>
        {props.title in connectionImgs
            ?
                <img
                    className={styles.connectionImage}
                    src={connectionImgs[props.title]}
                />
            :
            (
                props.title === 'malrang' ?
                    <Carousel
                        className={styles.connectionImage}
                        imgs={[connectionImgs.malrangBlueTooth, connectionImgs.malrangUsb]}
                        duration={imgSlideDuration}
                    />
                : 
                props.title === 'technic' ? 
                    <Carousel
                        className={styles.connectionImage}
                        imgs={[connectionImgs.technicBlueTooth, connectionImgs.technicUsb]}
                        duration={imgSlideDuration}
                    />
                :
                isByrobotDroneExtension(props.title) ?
                    <img
                        className={styles.connectionImage}
                        src={droneConnectionSrc[document.documentElement.lang] ?? droneConnectionSrc.en}
                    />
                :
                    <img
                        className={styles.radarBig}
                        src={radarIcon}
                    />
            )
        }
        {(props.title === 'malrang' || props.title === 'technic' || isByrobotDroneExtension(props.title) || props.title in connectionImgs) ? '' :
            <img
                className={classNames(styles.bluetoothCenteredIcon, styles[`${props.title}-bluetooth-centered-icon`])}
                src={bluetoothIcons[props.title] ? bluetoothIcons[props.title] : bluetoothIcon}
            />}
    </React.Fragment>
   )
};

export default PreScanImage;