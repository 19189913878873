import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Dots from '../dots.jsx';
import Box from '../../box/box.jsx';
import styles from '../connection-modal.css';
import { PHASES } from '../auto-scanning-step.jsx';

import closeIcon from '../../close-button/icon--close.svg';
import backIcon from '../icons/back.svg';

const getDotProgress = (phase) => {
    if (phase === PHASES.prescan || phase === PHASES.setPort) {
        return 0;
    }
    if (phase === PHASES.pressbutton) {
        return 1;
    }
    return -1;
}

const AutoScanningButtons = (props) => {
    return (
        <>
            {props.title !== 'nemo' && (
                <Dots
                    className={styles.bottomAreaItem}
                    error={props.phase === PHASES.notfound}
                    counter={getDotProgress(props.phase)}
                    total={3}
                />
            )}
            {props.phase === PHASES.setPort && (
                <Box className={styles.instructions}>
                    <input
                        className={styles.nemoPortInput}
                        autoFocus
                        type="text"
                        onChange={props.onChange}
                        placeholder={'네모링크에 표시된 포트번호를 입력해주세요.'}
                    />
                </Box>
            )}
            <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                {(props.phase === PHASES.setPort || props.phase === PHASES.prescan) && (
                    <button
                        className={styles.connectionButton}
                        onClick={props.phase === PHASES.prescan ? props.onStartScan : props.onSetNemoSerialPort}
                    >
                        <FormattedMessage
                            defaultMessage="Start Searching"
                            description="Button in prompt for starting a search"
                            id="gui.connection.auto-scanning.start-search"
                        />
                    </button>
                )}
                {(props.phase === PHASES.prescan && props.title === 'technic') && (
                    <button
                        className={classNames(styles.nemo_port_button, styles.connectionButton)}
                        onClick={props.handleOpenScratchHexModal}
                    >
                        스크래치 모드 활성화
                    </button>
                )}
                {(props.phase === PHASES.setPort && props.title === 'nemo') && (
                    <button
                        className={classNames(styles.nemo_port_button, styles.connectionButton)}
                        onClick={props.onClickGoBackButton}
                    >
                        {'뒤로가기'}
                    </button>
                )}
                {(props.phase === PHASES.prescan && props.title === 'nemo') && (
                    <button
                        className={classNames(styles.nemo_port_button, styles.connectionButton)}
                        onClick={props.onShowPortInput}
                    >
                        {'포트번호 입력하기'}
                    </button>
                )}
                {props.phase === PHASES.pressbutton && (
                    <div className={styles.segmentedButton}>
                        <button
                            disabled
                            className={styles.connectionButton}
                        >
                            <FormattedMessage
                                defaultMessage="Searching..."
                                description="Label indicating that search is in progress"
                                id="gui.connection.connecting-searchbutton"
                            />
                        </button>
                        <button
                            className={styles.connectionButton}
                            onClick={props.onRefresh}
                        >
                            <img
                                className={styles.abortConnectingIcon}
                                src={closeIcon}
                            />
                        </button>
                    </div>
                )}
                {props.phase === PHASES.notfound && (
                    <button
                        className={styles.connectionButton}
                        onClick={props.onRefresh}
                    >
                        <img
                            className={styles.buttonIconLeft}
                            src={backIcon}
                        />
                        <FormattedMessage
                            defaultMessage="Try again"
                            description="Button in prompt for trying a device search again"
                            id="gui.connection.auto-scanning.try-again"
                        />
                    </button>
                )}
            </Box>
        </>
    );
};

export default AutoScanningButtons;